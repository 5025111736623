.toasts-wrapper {
  position: fixed;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column-reverse;

  .toast {
    margin: 8px;
    overflow: hidden;
    word-break: break-all;
    max-width: 360px;
    min-width: 200px;

    h1 {
      margin-right: 8px;
    }
  }
}
