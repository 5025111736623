@font-face {
  font-family: 'LibreFranklin Light';
  src: local('LibreFranklin Light'), url(./LibreFranklin-Light.woff) format('woff');
}

@font-face {
  font-family: 'LibreFranklin Regular';
  src: local('LibreFranklin Regular'), url(./LibreFranklin-Regular.woff) format('woff');
}

@font-face {
  font-family: 'LibreFranklin Semibold';
  src: local('LibreFranklin Semibold'), url(./LibreFranklin-Semibold.woff) format('woff');
}

@font-face {
  font-family: 'LibreFranklin Bold';
  src: local('LibreFranklin Bold'), url(./LibreFranklin-Bold.woff) format('woff');
}
