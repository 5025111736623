
.contact-information-form {
  input {
    height: 32px;
    font-size: $font-size-sm;

    &:read-only {
      background: transparent !important;
    }
  }

  label {
    margin-left: 0;
  }

  > div > div + div {
    margin-top: 16px;
  }
}
