/*
 * This style applies the same styles as react-rainbow
 * textarea component.
 */
.textarea-display {
  white-space: pre-wrap;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.87);
  padding: 10px 16px;
}
