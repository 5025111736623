.external-link {
  height: 32px;

  a {
    span {
      margin-right: 0.5em;
      text-transform: lowercase;
    }
  }
}
