* {
  outline: none;
}

html {
  height: 100%;
}

body {
  height: 100%;
  background-color: $background-color;

  .header,
  .footer {
    display: flex;
    color: $white;
    align-items: center;
    justify-content: center;
  }

  a {
    text-decoration: none !important;
  }

  a,
  a:hover {
    text-decoration: none;
  }

  input:focus {
    outline: none;
  }

  td:focus > div {
    border: none;
  }
}
