.bullet-chart {
  position: relative;
  display: flex;
  align-items: center;
  height: 70px;
  background-color: #bfbfff;

  .bullet-chart-range {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;

    span {
      position: absolute;
      top: 100%;
      left: 100%;
      font-size: 10px;
      font-weight: 600;
      white-space: nowrap;
      transform: translateX(-50%);
      padding: 2px 0;
    }
  }

  .bullet-chart-line {
    position: absolute;
    top: 0;
    height: 100%;
    border-left: 1px solid rgba(255, 255, 255, 0.5);
  }

  .bullet-chart-goal {
    position: absolute;
    height: 100%;
    border-left: 2px solid #333;
    font-size: 12px;
    line-height: 1;
    text-align: center;

    span {
      position: absolute;
      top: 100%;
      left: 100%;
      font-size: 10px;
      font-weight: 600;
      white-space: nowrap;
      transform: translateX(-50%);
      padding: 2px 0;
    }
  }

  .bullet-chart-current {
    position: absolute;
    height: 100%;
    border-left: 2px solid #333;
    line-height: 1;
    text-align: center;

    > span {
      position: absolute;
      bottom: 100%;
      left: 100%;
      font-size: 11px;
      font-weight: 600;
      white-space: nowrap;
    }
  }
}
