.header-wrapper {
  height: 60px;
  // padding: 0 32px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  background-color: $primary-color;
  position: relative;

  svg {
    color: $white;
  }

  button:focus {
    box-shadow: none;
  }

  .admin-impersonation-picklist {
    input {
      color: $white;

      &::placeholder {
        color: $white;
      }

      &:hover {
        color: $black;

        &::placeholder {
          color: $black;
        }
      }
    }

    span {
      &::after {
        color: $white;
      }
    }

    &:hover {
      span::after {
        color: $primary-color-light;
      }
    }
  }

  .links-wrapper {
    display: flex;
    height: 100%;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $white;
      margin: auto 20px;
      height: 100%;
      position: relative;
      text-align: center;
      font-family: $font-light;
      font-size: $font-size-md;

      &.selected {
        text-shadow: 0 0 0.9px $white, 0 0 0.9px $white, 0 0 0.9px $white;

        &::after {
          content: "";
          display: flex;
          height: 4px;
          background: $primary-color-light;
          position: absolute;
          bottom: -2px;
          left: 50%;
          transform: translate(-50%, 0);
          animation: width 0.3s forwards;
        }

        @keyframes width {
          0% {
            width: 0;
          }

          100% {
            width: 100%;
          }
        }
      }
    }
  }
}
