.slideIn {
  animation: slideIn 0.5s forwards;

  @keyframes slideIn {
    0% {
      transform: translateX(-50%);
      opacity: 0;
    }

    100% {
      transform: translateX(0%);
      opacity: 1;
    }
  }
}

.custom-card-wrapper {
  padding: 10px;

  a {
    color: $secondary-color;

    &:hover {
      color: $secondary-color-light;
      transition: color 0.2s ease;
    }
  }

  label {
    margin-bottom: 8px;
  }

  .custom-card-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 32px;

    h3 {
      font-size: $font-size-lg;
      color: $gray;
      font-family: $font-medium;
      margin-bottom: 16px;
    }

    span {
      font-size: $font-size-md;
      color: $gray;
      font-family: $font-regular;
    }
  }

  .custom-card {
    min-width: 300px;
    min-height: 300px;
    padding: 32px;
    transition: box-shadow 0.4s ease-out;
  }

  @media screen and (max-width: $screen-xs) {
    .custom-card {
      min-width: 100%;

      input::placeholder {
        font-size: $font-size-sm;
      }
    }

    .custom-card-header {
      text-align: center;
    }
  }
}
