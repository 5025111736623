.reset-password-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  .reset-password-info {
    font-family: $font-light;
    text-align: center;
    font-size: 16px;
    color: $gray;
    margin: 16px 0 32px 0;
  }

  .reset-password-input-field {
    margin-bottom: 24px;
  }

  .reset-password-actions-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    button {
      width: 100%;
    }

    a {
      text-decoration: none;
      margin-top: 12px;
      color: $secondary-color;
    }
  }
}
