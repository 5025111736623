// VARIABLES (Must be above all imports that uses it)
@import "app/essentials/variables";

// LAYOUT
@import "app/essentials/layout";

// VIEWS
@import "app/views/home";
@import "app/views/login";
@import "app/views/signUp";
@import "app/views/forgotPassword";
@import "app/views/resetPassword";
@import "app/views/index";

// COMPONENTS
@import "app/components/header";
@import "app/components/customCard";
@import "app/components/toastsContainer";
@import "app/components/dateFilter";
@import "app/components/table";
@import "app/components/searchFilter";
@import "app/components/contactInformationFields";
@import "app/components/externalLink";
@import "app/components/textareaDisplay";

// ESSENTIALS
@import "app/essentials/texts";
@import "app/essentials/margins";
@import "app/essentials/print";

// OTHER LIBRARIES
// -

.pl-10 {
  padding-left: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-30 {
  padding-right: 30px;
}

.chartjs-size-monitor-expand > div {
  width: initial !important;
  height: initial !important;
}

.full-width {
  width: 100%;
}

.full-width div {
  width: 100%;
}

input[name="ineligibleReason"] {
  min-width: 400px;
}

.chat-history-container {
  width: 400px;
  min-width: 400px;
  // box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
  border-right: unset;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  display: flex;
  height: calc(100vh - 60px);
  flex-direction: column;
  background-color: #f2f1f1;
}

.chat-container {
  // flex-grow: 1;
  border: 1px solid #ddd;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #f2f1f1;
  display: flex;
  height: calc(100vh - 60px);
  width: calc(100vw - 400px);
  max-width: calc(100vw - 400px);
}

.chat-container .chat-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.chat-container .chat-banner {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.chat-container .new-chat-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.chat-container .new-chat-form h2 {
  font-weight: bold;
  font-size: 20px;
}

.chat-container .new-chat-form h4 {
  font-weight: normal;
  font-size: 16px;
}

.chat-container .input-group {
  display: flex;
  flex-direction: column;
  height: 40px;
}

.chat-container .messages {
  flex-grow: 1;
  padding: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.chat-container .messages .messages-holder {
  display: flex;
  flex-direction: column;
  min-height: fit-content;
  height: fit-content;
}

.chat-container .input-box {
  display: flex;
  padding: 10px;
}

.chat-container .input-box input {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
  font-size: 16px;
  background-color: #fdfafa;
}

#fineTunningVersionInput {
  flex-grow: 1;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
  font-size: 16px;
  background-color: #fdfafa;
}

.chat-container .input-box input:disabled,
#fineTunningVersionInput:disabled {
  cursor: progress;
  visibility: 90%;
}

.chat-container .input-box button {
  padding: 10px 20px;
  border: none;
  background-color: #0C006F;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.chat-container .input-box button:disabled {
  cursor: progress;
  background-color: #7f9ebe;
}

.chat-container .input-box button:hover {
  background-color: #2A2AC9;
}

.header-wrapper .links-wrapper .menu-dropdown-button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  height: 100%;
  position: relative;
  text-align: center;
  font-family: "LibreFranklin Light", sans-serif;
  font-size: 16px;
}

.header-wrapper .links-wrapper .menu-dropdown-button button {
  border-radius: 0;
  width: fit-content;
}

.chat-container .input-box button:disabled:hover {
  cursor: progress;
  background-color: #7f9ebe;
}

/* Message styling */
.chat-container .message {
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 5px;
  max-width: 75%;
  // font-size: 18px;
  // font-weight: bolder;
  // box-shadow: 0 2px 10px rgba(0,0,0,0.1);
}

.chat-container .user {
  color: #11085B;
  align-self: flex-end;
  background-color: #F1F1FF;
  box-shadow: 1px 3px 5px 1px #ccccccb0;
  border: 1px solid #d4d2d2;
  max-width: 60%;
}

.chat-container .assistant {
  align-self: flex-start;
  background-color: #f9f7f7eb;
  box-shadow: 1px 3px 5px 1px #ccccccb0;
  margin-bottom: 20px;
  border: 1px solid #d4d2d2;
  color: black;
  position: relative;
  max-width: 60%;
  overflow-y: scroll;
}

.chat-container .assistant.database {
  max-width: 95%;
}

.chat-container #chatForm {
  position: relative;
}

.chat-container .loader {
  position: absolute;
  right: 50%;
  top: -50vh;
}

.chat-container .chat-error {
  display: flex;
  flex-direction: row;
  background-color: rgb(251, 210, 210);
  padding: 0.2rem;
  min-width: 100%;
  max-width: 100%;
  width: 100%;
  overflow-x: hidden;
  justify-content: space-between;
  align-items: center;
  min-height: 48px;
}

.chat-container .error-message {
  font-weight: bold;
  color: rgb(225, 1, 1);
  margin-left: 5px;
}

.chat-container .close-error-btn {
  font-weight: bold;
  padding: 5px;
  cursor: pointer;
  margin-right: 5px;
}

.chat-container .close-error-btn:hover {
  font-weight: bolder;
}

.chat-history-container .new-chat-btn {
  padding: 5px 20px;
  border: none;
  background-color: #0C006F;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.chat-history-container .new-chat-btn:disabled {
  cursor: progress;
  background-color: #7f9ebe;
}

.chat-history-container .new-chat-btn:hover {
  background-color: #2A2AC9;
}

.chat-container .chat-settings-btn {
  padding: 5px 10px;
  border: none;
  background-color: #0C006F;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.chat-container .chat-settings-btn:disabled {
  cursor: progress;
  background-color: #7f9ebe;
}

.chat-container .chat-settings-btn:hover {
  background-color: #2A2AC9;
}

.chat-history-container .chat-historical-prompts {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
}

.chat-history-container .history-item {
  width: 100%;
  display: flex;
  background-color: #fff;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 10px;
  flex-direction: column;
  box-shadow: 1px 1px #dddddd;
}

.chat-history-container .history-item:hover {
  background-color: #BFBFFF;
}

.chat-history-container .history-item.active {
  background-color: #9082fb36;
  color: #11085B;
  font-weight: bold;
}

.chat-history-container .history-item .date {
  font-size: 10px;
  width: 100%;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #989797;
}

.chat-history-container .history-item .date .chat-history-id {
  color: #989797;
}

.chat-history-container .history-item .title {
  padding-top: 5px;
  color: black;
}

.chat-history-container .history-item:hover .title {
  color: #11085B;
}

/* Remove extra margin from paragraphs */
.ulysses-ai-summary p {
  margin: 0;
}

.chat-container .messages-holder .message h3 {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: bold;
  margin-top: 15px;
}

.chat-container .messages-holder .message p {
  margin-bottom: 10px;
  font-size: 14px;
}

.chat-container .messages-holder .message ul {
  padding-left: 40px;
  margin-bottom: 10px;
  font-size: 14px;
  list-style: disc;
}

.chat-container .message-files-list {
  display: flex;
  flex-direction: column;
  border-top: 1px solid #CCC;
  padding-top: 10px;
}

.chat-container .message-files-list-item-doc-key {
  margin-right: 10px;
}

.chat-container .message-files-list-item {
  padding-top: 2px;
  padding-bottom: 2px;
}

.ulysses-version-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #d4d2d25c;
  border: 1px solid #ccccccc1;
  padding: 4px;
  cursor: pointer;
  border-radius: 4px;
}

.ulysses-version-item.active {
  background-color: #9082fb36;
  border: 1px solid #ccc;
}

.ulysses-version-item:hover {
  background-color: #9f93f90e;
  border: 1px solid #ccc;
}

.ulysses-version-item .status {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 8px;
  margin-bottom: 5px;
}

.ulysses-version-item .version {
  font-weight: bolder;
  font-size: 12px;
}

.version-new-btn {
  padding: 5px;
  border: none;
  background-color: #0C006F;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  font-size: 10px;
}

.version-new-btn:disabled {
  cursor: progress;
  background-color: #7f9ebe;
}

.version-new-btn:hover {
  background-color: #2A2AC9;
}

.change-layout-btn {
  padding: 2px;
  border: none;
  background-color: #0C006F;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  font-size: 10px;
}

.change-layout-btn:disabled {
  cursor: progress;
  background-color: #7f9ebe;
}

.change-layout-btn:hover {
  background-color: #2A2AC9;
}

.tox-statusbar__right-container {
  color: transparent;
}

.ulysses-settings-loader {
  width: 100%;
  position: absolute;
  background-color: #7e7e7e12;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  top: 0;
  left: 0;
}

.no-fine-tunning {
  position: absolute;
  bottom: -26px;
  background: #f8f7f7;
  padding: 4px;
  border-radius: 0 0 4px 4px;
  left: -1px;
  box-shadow: 1px 3px 5px 1px rgba(204, 204, 204, 0.3);
  border: 1px solid #d4d2d2;
  border-top: none;
  font-style: italic;
}

.menu-item-link {
  line-height: 1.5;
  width: 100%;
  background: none;
  border: none;
  outline: inherit;
  margin: 0;
  -webkit-transition: color 0.1s linear;
  transition: color 0.1s linear;
  position: relative;
  font-size: 0.875rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
  white-space: nowrap;
  cursor: pointer;
  -webkit-text-decoration: none;
  text-decoration: none;
  box-sizing: border-box;
}

.menu-item-link:active,
.menu-item-link:hover {
  background-color: rgba(228, 228, 228, 1);
  outline: 0;
}

.menu-item-link a {
  min-width: 100%;
  min-height: 100%;
  padding: 0.75rem 0.75rem;
}

.has-checvron-down::after {
  content: '';
  position: absolute;
  display: block;
  bottom: 45%;
  right: 0;
  pointer-events: none;
  width: 0.45rem;
  height: 0.45rem;
  border-style: solid;
  border-width: 0.15em 0.15em 0 0;
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
  // color: rgba(135, 0, 249, 1);
  color: #fff;
  box-sizing: border-box;
}

.chat-container ol {
  list-style: decimal;
  padding-left: 25px;
}

.ulysses-ai-data {
  overflow-x: auto;
  margin: 10px 0;
  border-top: 1px solid #cccccc5e;
  padding-top: 15px;
}

.ulysses-ai-role-admin .ulysses-ai-data,
.ulysses-ai-role-manager .ulysses-ai-data {
  display: block;
  border-top: 0;
  padding-top: 0;
}

.ulysses-ai-data table {
  width: fit-content;

  // position: relative; /* Ensure the tooltip can escape the table bounds */
  overflow: visible;

  /* Allow tooltips to overflow */
}

.ulysses-ai-data table thead {
  background-color: #0C006F;
  color: white;
  border-bottom: 1px solid #ccc;
}

.ulysses-ai-data table thead th {
  border-right: 1px solid #4b495e;
  border-left: 1px solid #4b495e;
  padding: 0 5px 0 5px;
  text-transform: capitalize;
}

.ulysses-ai-data table tbody tr {
  border-bottom: 1px solid #ccc;
}

.ulysses-ai-data table tbody tr:nth-child(odd) {
  background-color: #eeeaea;
}

.ulysses-ai-data table tbody tr td {
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
  padding: 5px 10px 5px 10px;
}

.ulysses-ai-data .whitespace-nowrap {
  white-space: nowrap;
}

.ulysses-ai-data .truncate-cell {
  max-width: 350px;

  /* Set the maximum width, almost 50 characters */
  overflow: hidden;

  /* Hide overflow content */
  text-overflow: ellipsis;

  /* Add ellipsis for overflowing text */
  white-space: nowrap;

  /* Prevent text wrapping */
  // position: relative; /* Ensure proper positioning */
}

/* Tooltip styling */
.ulysses-ai-data .truncate-cell .tooltip {
  display: none;

  /* Hidden by default */
  position: absolute;
  right: 0;
  z-index: 1000;
  background-color: #333;

  /* Dark background for the tooltip */
  color: #fff;

  /* White text for the tooltip */
  padding: 5px;
  border-radius: 4px;
  max-width: 300px;

  /* Optional: limit the tooltip's width */
  white-space: normal;

  /* Allow text wrapping inside the tooltip */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);

  /* Optional: Add a shadow */
}

.ulysses-ai-data .truncate-cell:hover .tooltip {
  display: block;

  /* Show the tooltip on hover */
}

.ulysses-ai-data .header-cell {
  white-space: nowrap;

  /* Prevent text wrapping */
  padding: 4px 10px;
}

.ulysses-ai-sanitized_query {
  white-space: pre-wrap;

  /* Preserves whitespace and wraps lines */
  font-family: "Courier New", Courier, monospace;

  /* Optional: Use a monospace font for SQL */
  font-size: 16px;
  background-color: #f4f4f4;
  color: #262525;
  padding: 15px;
  margin: 5px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow-x: auto;

  /* Enables horizontal scrolling if needed */
  // box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  line-height: 1.5;
  display: none;
}

.ulysses-ai-role-admin .ulysses-ai-sanitized_query,
.ulysses-ai-role-manager .ulysses-ai-sanitized_query {
  display: block;
}

/* Keyword styling */
.ulysses-ai-sanitized_query::before {
  content: attr(data-query);
  white-space: pre-wrap;
  display: block;
}

/* Highlight SQL keywords */
// .ulysses-ai-sanitized_query[data-query*="SELECT"],
// .ulysses-ai-sanitized_query[data-query*="FROM"],
// .ulysses-ai-sanitized_query[data-query*="JOIN"],
// .ulysses-ai-sanitized_query[data-query*="ON"],
// .ulysses-ai-sanitized_query[data-query*="WHERE"],
// .ulysses-ai-sanitized_query[data-query*="GROUP BY"],
// .ulysses-ai-sanitized_query[data-query*="ORDER BY"],
// .ulysses-ai-sanitized_query[data-query*="LIMIT"],
// .ulysses-ai-sanitized_query[data-query*="AS"],
// .ulysses-ai-sanitized_query[data-query*="DESC"],
// .ulysses-ai-sanitized_query[data-query*="SUM"] {
//   color: #d73a49;
//   font-weight: bold;
// }
.ulysses-ai-sanitized_query .keyword {
  color: #503fdb;
  font-weight: bold;
}

.ulysses-ai-sanitized_query .function {
  color: #1f6feb;
  font-weight: bold;
}

.ulysses-ai-summary {
  background-color: #f8f9fa;

  /* Light gray background */
  padding: 5px 0;

  /* Add padding around the content */
  font-size: 1rem;

  /* Standard font size */
  color: #26292d;

  /* Dark gray text for readability */
  line-height: 1.5;

  /* Line spacing for better readability */
  font-family: 'Arial', sans-serif;

  /* Clean and modern font */
}

.ulysses-ai-query {
  display: none;
}

.ulysses-ai-summary strong {
  font-weight: bold;

  /* Make any strong text bold */
  color: #1b0248;

  /* Use blue color for emphasis */
}

.ulysses-ai-summary em {
  font-style: italic;

  /* Italics for any emphasized text */
  color: #282d30;

  /* Light gray color for emphasis */
}

.login-with-google-btn {
  transition: background-color 0.3s, box-shadow 0.3s;
  padding: 12px 16px 12px 42px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
  color: #757575;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 11px;
  cursor: pointer;

  &:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25);
  }

  &:active {
    background-color: #eeeeee;
  }

  &:focus {
    outline: none;
    box-shadow:
      0 -1px 0 rgba(0, 0, 0, 0.04),
      0 2px 4px rgba(0, 0, 0, 0.25),
      0 0 0 3px #c8dafc;
  }

  &:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
}

.login-with-microsoft-btn {
  transition: background-color 0.3s, box-shadow 0.3s;
  padding: 12px 16px 12px 42px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
  color: #757575;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAIAAAD8GO2jAAAABnRSTlMAAAAAAABupgeRAAAAWklEQVR4AWP4FKBEJPrip8wABrV7GOp3EYtGhgWjFoxaMGrBqAXf/OSIRD995CEWVO9hqN1FLBoOYNlnotF3iI7/O4lHQOVL3hONPo9agB2NWjBqwagFoxYAAB2zMZ7bQW2tAAAAAElFTkSuQmCC);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 11px;
  background-size: 18px 18px;
  cursor: pointer;

  &:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25);
  }

  &:active {
    background-color: #eeeeee;
  }

  &:focus {
    outline: none;
    box-shadow:
      0 -1px 0 rgba(0, 0, 0, 0.04),
      0 2px 4px rgba(0, 0, 0, 0.25),
      0 0 0 3px #c8dafc;
  }

  &:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
}
