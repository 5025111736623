.show-on-print {
  display: none !important;
}

.show-on-print-only {
  visibility: hidden;
  height: 0;
  overflow: hidden;
}

@media print {
  .hide-on-print {
    display: none !important;
  }

  .show-on-print {
    display: block !important;
  }

  .show-on-print-only {
    visibility: visible;
    height: auto;
    overflow: visible;
  }

  .hide-on-print-selected {
    display: none !important;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }

  @page {
    size: auto;
    margin: 20mm;
  }
}
