.sign-up-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  .sign-up-input-field {
    margin-bottom: 24px;
  }

  .sign-up-actions-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    button {
      width: 100%;
    }

    a {
      text-decoration: none;
      margin-top: 12px;
      color: $secondary-color;
    }
  }
}
