.table {
  span {
    text-align: center;
    font-size: $font-size-sm;
    text-transform: capitalize;
    margin: 0 auto;
    word-break: normal !important;
    white-space: normal !important;
  }

  border: 1px solid $gray;
}
