// MARGIN 6
.m-6,
.m-x-6,
.m-r-6 {
  margin-right: 6px !important;
}

.m-6,
.m-x-6,
.m-l-6 {
  margin-left: 6px !important;
}

// MARGIN 10
.m-10,
.m-y-10,
.m-t-10 {
  margin-top: 10px !important;
}

.m-10,
.m-y-10,
.m-b-10 {
  margin-bottom: 10px !important;
}

.m-10,
.m-x-10,
.m-r-10 {
  margin-right: 10px !important;
}

// MARGIN 15
.m-15,
.m-y-15,
.m-t-15 {
  margin-top: 15px !important;
}

.m-15,
.m-y-15,
.m-b-15 {
  margin-bottom: 15px !important;
}

.m-15,
.m-x-15,
.m-r-15 {
  margin-right: 15px !important;
}

.m-15,
.m-x-15,
.m-l-15 {
  margin-left: 15px !important;
}

// MARGIN 18
.m-18,
.m-y-18,
.m-t-18 {
  margin-top: 18px !important;
}

.m-18,
.m-y-18,
.m-b-18 {
  margin-bottom: 18px !important;
}

// MARGIN 24
.m-24,
.m-y-24,
.m-t-24 {
  margin-top: 24px !important;
}

.m-24,
.m-y-24,
.m-b-24 {
  margin-bottom: 24px !important;
}

// MARGIN 28
.m-28,
.m-y-28,
.m-t-28 {
  margin-top: 28px !important;
}

.m-28,
.m-y-28,
.m-b-28 {
  margin-bottom: 28px !important;
}

// MARGIN 30
.m-30,
.m-y-30,
.m-t-30 {
  margin-top: 30px !important;
}

.m-30,
.m-y-30,
.m-b-30 {
  margin-bottom: 30px !important;
}

// MARGIN 37
.m-37,
.m-y-37,
.m-t-37 {
  margin-top: 37px !important;
}

.m-37,
.m-y-37,
.m-b-37 {
  margin-bottom: 37px !important;
}

// MARGIN 42
.m-42,
.m-y-42,
.m-t-42 {
  margin-top: 42px !important;
}

.m-42,
.m-y-42,
.m-b-42 {
  margin-bottom: 42px !important;
}

// MARGIN 50
.m-50,
.m-y-50,
.m-t-50 {
  margin-top: 50px !important;
}

.m-50,
.m-y-50,
.m-b-50 {
  margin-bottom: 50px !important;
}

// MARGIN 75
.m-75,
.m-y-75,
.m-t-75 {
  margin-top: 75px !important;
}

.m-75,
.m-y-75,
.m-b-75 {
  margin-bottom: 75px !important;
}

// MARGIN 184
.m-184,
.m-y-184,
.m-t-184 {
  margin-top: 184px !important;
}

.m-184,
.m-y-184,
.m-b-184 {
  margin-bottom: 184px !important;
}

// MARGIN 253
.m-253,
.m-y-253,
.m-t-253 {
  margin-top: 253px !important;
}

.m-253,
.m-y-253,
.m-b-253 {
  margin-bottom: 253px !important;
}
