.date-input {
  cursor: pointer;

  input {
    cursor: pointer !important;
  }
}

.swap-button {
  box-shadow: none !important;

  &:focus {
    color: white !important;
  }
}
