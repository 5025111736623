.text-sm {
  font-size: 12px;
  font-family: $font-regular;
}

.h1 {
  font-size: 24px;
  font-family: $font-regular;
}

.h2 {
  font-size: 20px;
  font-family: $font-regular;
}

.h3 {
  font-size: 16px;
  font-family: $font-regular;
}

.h4 {
  font-size: 14px;
  font-family: $font-regular;
}

.h5 {
  font-size: 12px;
  font-family: $font-regular;
}

.h6 {
  font-size: 10px;
  font-family: $font-regular;
}

.text-gray {
  color: $gray;
}

.text-white {
  color: $white;
}

.text-primary {
  color: $primary-color;
}

.text-secondary {
  color: $secondary-color;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-bold {
  font-family: $font-bold;
}

.text-underline {
  text-decoration: underline;
}

.text-underline-on-hover {
  &:hover {
    text-decoration: underline;
  }
}

.text-area {
  textarea {
    font-size: 13px;

    &:read-only {
      padding: 10px 16px;
    }
  }
}

div[id^="error-message"] {
  text-transform: initial;

  &::first-letter {
    text-transform: uppercase;
  }
}
