// COLORS
$primary-color: #0C006F;
$primary-color-light: #8700F9;
$secondary-color: #283DFC;
$secondary-color-light: #00CFD8;
$black: #000;
$white: #fff;
$gray: #8D8D8D;
$gray-light: #CCCCCC;
$background-color: #F8F8F8;

// BREAKPOINTS
$screen-xs: 480px;
$screen-sm: 575px;
$screen-md: 767px;
$screen-lg: 991px;
$screen-xl: 1199px;

// FONT FAMILY
$font-light: "LibreFranklin Light", sans-serif;
$font-regular: "LibreFranklin Regular", sans-serif;
$font-medium: "LibreFranklin SemiBold", sans-serif;
$font-bold: "LibreFranklin Bold", sans-serif;

// FONT SIZES
$font-size-sm: 14px;
$font-size-md: 16px;
$font-size-lg: 20px;

// BOX SHADOW
$box-shadow-default: 0 0 10px 2px rgba(0, 0, 0, 0.1);
